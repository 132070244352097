<template>
	<div id="teacherlist">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>基础配置</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/statement' }">报表中心</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">教师数据报表</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f-item u-f-jsb" style="padding-right: 15px;border-bottom: 1px solid #EEEEEE;">
					<div class="title">共{{total}}条数据</div>
					<el-button size="mini" type="primary" @click="teacherDerive">导出</el-button>
				</div>
				<div style="padding:0 15px;">
					<el-table ref="multipleTable" :data="List" tooltip-effect="dark" style="height: 73vh;" @selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column type="index" label="序号"></el-table-column>
						<el-table-column prop="username" label="姓名"></el-table-column>
						<el-table-column prop="gender" label="性别" :filters="[{ text: '男', value: '1' }, { text: '女', value: '2' }]" :filter-method="filtersex">
							<template slot-scope="scope">
								<div>{{scope.row.gender==1?'男':'女'}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="grade_name" label="年级"></el-table-column>
						<el-table-column
							prop="discipline_name"
							label="学科"
							:filters="[{ text: '语文', value: '1' }, { text: '数学', value: '2' }]"
							:filter-method="filtersubject"
						></el-table-column>
						<el-table-column
							prop="class_name"
							label="班级"
							:filters="[{ text: '高一2班', value: '1' }, { text: '高一3班', value: '2' }]"
							:filter-method="filterclasses"
						></el-table-column>
						<el-table-column prop="mobile" label="电话"></el-table-column>
						<el-table-column prop="entry" label="入职" sortable></el-table-column>
						<el-table-column
							prop="staff_type_name"
							label="性质"
							:filters="[{ text: '行政', value: '1' }, { text: '非行政', value: '2' }]"
							:filter-method="filternature"
						></el-table-column>
						<el-table-column
							prop="job_title_name"
							label="职称"
							:filters="[{ text: '无', value: '0' }, { text: '一级', value: '1' }, { text: '中级', value: '2' }, { text: '高级', value: '3' }]"
							:filter-method="filterrank"
						></el-table-column>
						<el-table-column
							prop="identity"
							label="身份"
							:filters="[
								{ text: '群众', value: '1' },
								{ text: '少先队员', value: '2' },
								{ text: '团员', value: '3' },
								{ text: '党员', value: '4' }
							]"
							:filter-method="filteridentity"
						>
							<template slot-scope="scope">
								<div>
									<blockquote style="margin:0" v-if="scope.row.identity==1">群众</blockquote>
									<blockquote style="margin:0" v-else-if="scope.row.identity==2">少先队员</blockquote>
									<blockquote style="margin:0" v-else-if="scope.row.identity==3">团员</blockquote>
									<blockquote style="margin:0" v-else-if="scope.row.identity==4">党员</blockquote>
								</div>
							</template>
						</el-table-column>
						<el-table-column
							prop="salary_name"
							label="工资"
							:filters="[{ text: '一级', value: '1' }, { text: '二级', value: '2' }, { text: '三级', value: '3' }]"
							:filter-method="filtersalary"
						></el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="13"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
import Progress from 'easy-circular-progress';
import index from '@/components/common/index.vue';
export default {
	components: {
		Progress,
		index
	},
	props: {},
	data() {
		return {
			page: 1,
			total: 0,
			List: [],
		};
	},
	mounted() {
		this.initData()
	},
	methods: {
		initData(){
			let data = {
				type: 1,
				year_id: window.localStorage.getItem('year_id'),
				page: this.page,
				limit:13
			}
			this.$api.teachers.teachersList(data).then(res => {
				if (res.data.code == 1) {
					this.List = res.data.data.rows;
					this.total = res.data.data.total;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		toggleSelection(rows) {
			if (this.List) {
				this.List.forEach(row => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.page = val;
			this.initData()
		},
		// 性别筛选
		filtersex(row, column) {
			return row.sex;
		},
		// 学科筛选
		filtersubject(row, column) {
			return row.subject;
		},
		// 班级筛选
		filterclasses(row, column) {
			return row.classes;
		},
		// 性质筛选
		filternature(row, column) {
			return row.nature;
		},
		// 职称筛选
		filterrank(row, column) {
			return row.rank;
		},
		// 工资筛选
		filtersalary(row, column) {
			return row.salary;
		},
		// 身份筛选
		filteridentity(row, column) {
			return row.identity;
		},
		// 导出
		teacherDerive(){
			this.$api.teachers.teacherDerive({}).then(res=>{
				if(res.data.code==1){
					location.href = res.data.data.url;
				}
			})
		}
	}
};
</script>

<style lang="scss">
#teacherlist {
	.head {
		background-color: #ffffff;
		padding: 8px 20px;
		margin-top: 15px;
		.main-tab-bars {
			border: 1px solid #dadfe0;
			.main-tab-item {
				height: 30px;
				line-height: 30px;
				width: 100px;
				text-align: center;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.active {
				color: #67adf4;
				background-color: #e1f3ff;
				border: 1px solid #67adf4;
			}
		}
	}
	.main-item {
		background: #ffffff;
		margin-top: 15px;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
			color: #4e4e4e;
			border-bottom: 1px solid #fafafb;
		}
	}
	.content {
		background-color: #ffffff;
		margin-top: 15px;
		height: 85vh;
		overflow: hidden;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
		}
	}
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.vue-circular-progress .circle__progress {
		stroke-linecap: square;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 20px;
		.el-radio{
			margin-bottom: 10px;
			margin-top: 10px;
		}
	}
	.el-dialog__footer{
		border-top: 1px solid #EEEEEE;
		text-align: center;
	}
	.el-table__column-filter-trigger{
		.el-icon-arrow-down:before{
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
